import React from "react";
import { useTheme } from "emotion-theming";

import StyledFormLabel from "./FormLabel.style";

const FormLabel = ({ children, ...props }) => {
  const theme = useTheme();
  const { textColor } = theme.colors.background;
  return (
    <StyledFormLabel textColor={textColor} {...props}>
      {children}
    </StyledFormLabel>
  );
};

export default FormLabel;
