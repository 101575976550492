import React from "react";
import { Heading as ChakraHeading } from "@chakra-ui/core";

const Heading = ({ children, ...rest }) => {
  return (
    <ChakraHeading lineHeight="1.6" {...rest}>
      {children}
    </ChakraHeading>
  );
};

export default Heading;
