import React from "react";
import { Link, Box } from "@chakra-ui/core";

const CollectionItem = ({ color, onClick, children }) => {
  return (
    <Box as="li">
      <Link
        display="flex"
        padding="10px 8px"
        marginBottom="10px"
        color={color}
        paddingLeft="20px"
        fontWeight="bold"
        alignItems="center"
        _hover={{ textDecoration: "none", color: "brand.500" }}
        onClick={onClick}
      >
        {children}
      </Link>
    </Box>
  );
};

export default CollectionItem;
