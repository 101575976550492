/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Flex, Box } from "@chakra-ui/core";
import { useLocation, Link } from "react-router-dom";
import { Button, mq } from "@volter/shared";

import Logo from "../components/Logo";
import { ReactComponent as ComfortSvg } from "../assets/images/comfort.svg";

const withAuthenticationLayout = (Component) => {
  const WithAuthenticationLayout = (props) => {
    const { pathname } = useLocation();
    return (
      <Flex height="100%">
        <Flex flex="1" direction="column">
          <Flex
            align="center"
            marginTop="30px"
            marginLeft="15px"
            marginRight="15px"
          >
            <Logo
              width={["84px", "84px", "48px"]}
              height={["84px", "84px", "48px"]}
              margin={["0 auto", "0 auto", "0"]}
            />

            <Box marginLeft="auto" display={["none", "none", "block"]}>
              {pathname === "/signin" ? (
                <Button
                  type="button"
                  variant="outline"
                  variantColor="brand"
                  as={Link}
                  to="/signup"
                >
                  Go to Sign up page
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="outline"
                  variantColor="brand"
                  as={Link}
                  to="/signin"
                >
                  Go to Sign in page
                </Button>
              )}
            </Box>
          </Flex>

          <Component {...props} />

          <Flex
            height="60px"
            align="center"
            justify="center"
            display={["none", "none", "flex"]}
          >
            <Box color="neutral.700">
              Volter ©2020 Made with{" "}
              <span
                css={css`
                  color: #f4511e;
                `}
              >
                ❤
              </span>{" "}
              in Morocco
            </Box>
          </Flex>
        </Flex>
        <div
          css={css`
            ${mq({
              display: ["none", "none", "none"],
            })}
            align-items: center;
            width: 600px;
            background: #ffab9173;
          `}
        >
          <div
            css={css`
              width: 530px;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <p
              css={css`
                font-size: 2.25rem;
                font-weight: bold;
                color: #ea5629;
                line-height: 1.5;
              `}
            >
              The bookmark manager you were waiting for is finally here.
            </p>
            <ComfortSvg
              css={css`
                margin-top: 20px;
                width: 450px;
                height: 450px;
              `}
            />
          </div>
        </div>
      </Flex>
    );
  };

  return WithAuthenticationLayout;
};

export default withAuthenticationLayout;
