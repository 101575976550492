import React from "react";

import ArticleCard from "./ArticleCard";
import LinkCard from "./LinkCard";

const Card = ({ item }) => {
  const { type } = item || {};
  switch (type) {
    case "article":
      return <ArticleCard item={item} />;
    default:
      return <LinkCard item={item} />;
  }
};

export default Card;
