/**@jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import { Flex, Box } from "@chakra-ui/core";
import { compose } from "recompose";

import { withEmailVerification, withAuthorization } from "../../auth";

import Sidebar from "../Sidebar";
import Header from "../Header";

const MainLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Flex direction="row" minHeight="100%">
      <Sidebar isOpen={isOpen} />
      <Flex
        flex={1}
        direction="column"
        marginLeft={["0", "0", "275px"]}
        overflowX="hidden"
        overflowY="hidden"
      >
        <Header toggleMenu={() => setIsOpen(!isOpen)} />
        {children}
      </Flex>
      {isOpen && (
        <Box
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          background="#f1f1f10a"
          css={css`
            backdrop-filter: blur(3px);
          `}
          onClick={() => setIsOpen(false)}
        />
      )}
    </Flex>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(MainLayout);
