import styled from "@emotion/styled";

const baseFormLabelStyle = {
  display: "block",
  fontSize: "0.9rem",
  fontWeight: 600,
  marginBottom: "5px",
};

const StyledFormLabel = styled.label(({ textColor }) => ({
  ...baseFormLabelStyle,
  color: textColor,
}));

export default StyledFormLabel;
