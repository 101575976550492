import React from "react";
import { Input as ChakraInput } from "@chakra-ui/core";

const styles = {
  boxShadow:"md",
  borderColor: "transparent",
};

const Input = React.forwardRef((props, ref) => {
  return <ChakraInput ref={ref} bg="neutral.10" {...props} {...styles} />;
});

export default Input;
