/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { useFirebase } from "../firebase";
import AuthUserContext from "./AuthUserContext";

import * as ROUTES from "../constants/routes";

const withAuthorization = (condition) => (Component) => {
  const WithAuthorization = (props) => {
    const firebase = useFirebase();
    const history = useHistory();
    const authUser = useContext(AuthUserContext);

    useEffect(() => {
      let listener = firebase.onAuthUserListener(
        (authUser) => {
          if (!condition(authUser)) {
            history.push(ROUTES.SIGN_IN);
          }
        },
        () => history.push(ROUTES.SIGN_IN)
      );
      return () => {
        listener();
      };
    }, []);

    return condition(authUser) ? <Component {...props} /> : <div>Loading</div>;
  };

  return WithAuthorization;
};

export default withAuthorization;
