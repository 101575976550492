import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import AuthUserContext from "./AuthUserContext";

const withAlreadyAuthenticated = (Component) => {
  const WithAlreadyAuthenticated = (props) => {
    const authUser = useContext(AuthUserContext);
    return authUser ? (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    ) : (
      <Component {...props} />
    );
  };

  return WithAlreadyAuthenticated;
};

export default withAlreadyAuthenticated;
