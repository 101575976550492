/* eslint-disable react-hooks/exhaustive-deps */
/** @jsx jsx */
import React, { useState, useEffect, useContext } from "react";
import { jsx, css } from "@emotion/core";
import { Box, useTheme } from "@chakra-ui/core";
import {
  FiFolder,
  FiBookmark,
  FiArchive,
  FiStar,
  FiPlusCircle,
} from "react-icons/fi";

import { useFirebase } from "../../../firebase";
import { AuthUserContext } from "../../../auth";
import CollectionItem from "./CollectionItem";
import CollectionLoader from "./CollectionLoader";
import NewCollectionPopover from "./NewCollectionPopover";

const CollectionList = () => {
  const theme = useTheme();
  const authUser = useContext(AuthUserContext);
  const firebase = useFirebase();
  const [selected, setSelected] = useState(0);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const bootstrapCollections = async () => {
      try {
        const _collections = [];
        const { docs } = await firebase.getUserCollections(authUser.uid);
        docs.forEach((doc) => {
          _collections.push({ id: doc.id, ...doc.data() });
        });
        setCollections(_collections);
      } catch (err) {
        console.log(err);
      }
    };

    bootstrapCollections();
  }, []);

  return (
    <Box as="nav" width="15rem" position="relative" marginTop="40px">
      {collections.length > 0 ? (
        <React.Fragment>
          <Box
            as="span"
            bg="neutral.10"
            boxShadow="md"
            css={css`
              position: absolute;
              width: 100%;
              height: 45px;
              border-radius: 10px;
              transform: translateY(
                calc(100% * ${selected} + 10px * ${selected})
              );
              transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
              transition-property: transform;
              transition-duration: 200ms;
            `}
          />
          <Box as="ul" position="relative" listStyleType="none">
            <CollectionItem
              color={
                selected === 0
                  ? theme.colors.brand["500"]
                  : theme.colors.neutral["600"]
              }
              onClick={() => setSelected(0)}
            >
              <FiBookmark
                size={25}
                color={
                  selected === 0
                    ? theme.colors.brand["500"]
                    : theme.colors.neutral["400"]
                }
              />
              <span style={{ marginLeft: "10px" }}>All Bookmarks</span>
            </CollectionItem>
            <CollectionItem
              color={
                selected === 1
                  ? theme.colors.brand["500"]
                  : theme.colors.neutral["600"]
              }
              onClick={() => setSelected(1)}
            >
              <FiStar
                size={25}
                color={
                  selected === 1
                    ? theme.colors.brand["500"]
                    : theme.colors.neutral["400"]
                }
              />
              <span style={{ marginLeft: "10px" }}>Favorites</span>
            </CollectionItem>

            {collections.map((collection, index) => (
              <CollectionItem
                key={collection.id}
                color={
                  selected === index + 2
                    ? theme.colors.brand["500"]
                    : theme.colors.neutral["600"]
                }
                onClick={() => setSelected(index + 2)}
              >
                <FiFolder
                  size={25}
                  color={
                    selected === index + 2
                      ? theme.colors.brand["500"]
                      : theme.colors.neutral["400"]
                  }
                />
                <span style={{ marginLeft: "10px" }}>{collection.name}</span>
              </CollectionItem>
            ))}
            <CollectionItem
              color={
                selected === collections.length + 2
                  ? theme.colors.brand["500"]
                  : theme.colors.neutral["600"]
              }
              onClick={() => setSelected(collections.length + 2)}
            >
              <FiArchive
                size={25}
                color={
                  selected === collections.length + 2
                    ? theme.colors.brand["500"]
                    : theme.colors.neutral["400"]
                }
              />
              <span style={{ marginLeft: "10px" }}>Archives</span>
            </CollectionItem>
            <CollectionItem color={theme.colors.neutral["600"]}>
              <NewCollectionPopover>
                <FiPlusCircle size={25} color={theme.colors.neutral["400"]} />
                <span style={{ marginLeft: "10px" }}>New collection</span>
              </NewCollectionPopover>
            </CollectionItem>
          </Box>
        </React.Fragment>
      ) : (
        <CollectionLoader />
      )}
    </Box>
  );
};

export default CollectionList;
