/**@jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { Box, Flex, IconButton, Text } from "@chakra-ui/core";
import { FiExternalLink, FiBookOpen } from "react-icons/fi";

import { motion } from "framer-motion";

const MotionFlex = motion.custom(Flex);

const ArticleCard = ({ item }) => {
  const { title, image, logo, publisher, url, article, description, author } =
    item || {};
  return (
    <MotionFlex
      boxShadow="md"
      borderRadius="10px"
      gridColumn={["span 2", "span 4", "span 4"]}
      direction="row"
      bg="neutral.10"
      whileHover={{ scale: 1.05 }}
      variants={{
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1,
        },
      }}
    >
      <Flex direction="column" padding="10px" flex="1">
        <Text
          fontSize="lg"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          fontWeight="bold"
          color="neutral.600"
          width="250px"
        >
          {title}
        </Text>
        <Text fontSize="sm" marginTop="10px" color="neutral.500">
          {author}
        </Text>
        <Text fontSize="md" marginTop="10px" color="neutral.600">
          {description}
        </Text>
        <Flex marginTop="auto" alignItems="center">
          <Flex alignItems="center" marginLeft="5px">
            <img src={logo} width="24px" height="24px" />
            <Box as="span" marginLeft="8px" color="neutral.600">
              {publisher}
            </Box>
          </Flex>
          <IconButton
            variant="ghost"
            variantColor="neutral"
            aria-label="Call Sage"
            fontSize="20px"
            marginLeft="auto"
            icon={FiBookOpen}
          />
          <IconButton
            variant="ghost"
            variantColor="neutral"
            aria-label="Call Sage"
            fontSize="20px"
            marginLeft="10px"
            icon={FiExternalLink}
            onClick={() => window.open(url, "_blank")}
          />
        </Flex>
      </Flex>
      <Flex flex="1" d={["none", "flex", "flex"]}>
        <Box
          as="img"
          src={image}
          maxHeight="100%"
          minWidth="100%"
          objectFit="cover"
          borderTopRightRadius="10px"
          borderBottomRightRadius="10px"
        />
      </Flex>
    </MotionFlex>
  );
};

export default ArticleCard;
