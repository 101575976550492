/**@jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { Box, Flex, IconButton, Text } from "@chakra-ui/core";
import { FiExternalLink } from "react-icons/fi";
import { motion } from "framer-motion";

const MotionFlex = motion.custom(Flex);

const LinkCard = ({ item }) => {
  const { title, image, logo, publisher, url, article, type } = item || {};
  return (
    <MotionFlex
      boxShadow="0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06)"
      borderRadius="10px"
      gridColumn="span 2"
      direction="column"
      bg="neutral.10"
      whileHover={{ scale: 1.1 }}
      variants={{
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1,
        },
      }}
    >
      <Flex
        backgroundImage={`url('${image}')`}
        backgroundPosition="center"
        backgroundSize="cover"
        borderTopLeftRadius="10px"
        borderTopRightRadius="10px"
        borderBottomLeftRadius={type === "audio" && "10px"}
        borderBottomRightRadius={type === "audio" && "10px"}
        flex="1"
      />
      {title && type !== "audio" && (
        <Flex direction="column" padding="10px">
          <Text fontSize="lg" fontWeight="bold" color="neutral.600">
            {title}
          </Text>

          <Flex marginTop="10px" alignItems="center">
            <Flex alignItems="center" marginLeft="5px">
              <img src={logo} width="24px" height="24px" />{" "}
              <Box as="span" marginLeft="8px" color="neutral.600">
                {publisher}
              </Box>
            </Flex>
            <IconButton
              variant="ghost"
              variantColor="neutral"
              aria-label="Call Sage"
              fontSize="20px"
              marginLeft="auto"
              icon={FiExternalLink}
              onClick={() => window.open(url, "_blank")}
            />
          </Flex>
        </Flex>
      )}
    </MotionFlex>
  );
};

export default LinkCard;
