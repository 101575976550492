import React from "react";
import ContentLoader from "react-content-loader";

const CollectionLoader = (props) => (
  <ContentLoader
    speed={2}
    width={242}
    height={210}
    viewBox="0 0 242 210"
    backgroundColor="#f3f3f3"
    foregroundColor="#dbdbdb"
    {...props}
  >
    <rect x="0" y="1" rx="10" ry="10" width="240" height="45" />
    <rect x="0" y="55" rx="10" ry="10" width="240" height="45" />
    <rect x="0" y="110" rx="10" ry="10" width="240" height="45" />
    <rect x="0" y="165" rx="10" ry="10" width="240" height="45" />
  </ContentLoader>
);

export default CollectionLoader;
