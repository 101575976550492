import React, { useEffect, useState } from "react";

import { useFirebase } from "../firebase";

import AuthUserContext from "./AuthUserContext";

const withAuthentication = (Component) => {
  const WithAuthentication = (props) => {
    const firebase = useFirebase();
    const [authUser, setAuthUser] = useState();

    useEffect(() => {
      let listener = firebase.onAuthUserListener(
        (authUser) => {
          //localStorage.setItem("authUser", JSON.stringify(authUser));
          setAuthUser(authUser);
        },
        () => {
          //localStorage.removeItem("authUser");
          setAuthUser(null);
        }
      );
      return () => {
        listener();
      };
    }, []);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  };

  return WithAuthentication;
};

export default withAuthentication;
