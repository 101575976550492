import { theme } from "@chakra-ui/core";

// Let's say you want to add custom colors
export const lightTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brand: {
      900: "#bf360c",
      800: "#d84315",
      700: "#e64a19",
      600: "#f4511e",
      500: "#ff5722",
      400: "#ff7043",
      300: "#ff8a65",
      200: "#ffab91",
      100: "#ffccbc",
      50: "#fbe9e7",
    },
    neutral: {
      900: "#212121",
      800: "#424242",
      700: "#616161",
      600: "#757575",
      500: "#9e9e9e",
      400: "#bdbdbd",
      300: "#e0e0e0",
      200: "#eeeeee",
      100: "#f5f5f5",
      50: "#fafafa",
      10: "#fff"
    },
    blueGrey: {
      900: "#263238",
      800: "#37474f",
      700: "#455a64",
      600: "#546e7a",
      500: "#607d8b",
      400: "#78909c",
      300: "#90a4ae",
      200: "#b0bec5",
      100: "#cfd8dc",
      50: "#eceff1",
    },
  },
  shadows:{
    md: "rgba(2, 8, 20, 0.1) 0px 2px 4px, rgba(2, 8, 20, 0.08) 0px 1px 2px"
  }
};

export const darkTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brand: {
      900: "#bf360c",
      800: "#d84315",
      700: "#e64a19",
      600: "#f4511e",
      500: "#ff5722",
      400: "#ff7043",
      300: "#ff8a65",
      200: "#ffab91",
      100: "#ffccbc",
      50: "#fbe9e7",
    },
    neutral: {
      900: "#212121",
      800: "#424242",
      700: "#616161",
      600: "#757575",
      500: "#9e9e9e",
      400: "#bdbdbd",
      300: "#e0e0e0",
      200: "#eeeeee",
      100: "#171717",
      50: "#141414",
      10: "#212121"
    },
    blueGrey: {
      900: "#263238",
      800: "#37474f",
      700: "#455a64",
      600: "#546e7a",
      500: "#607d8b",
      400: "#78909c",
      300: "#90a4ae",
      200: "#b0bec5",
      100: "#cfd8dc",
      50: "#eceff1",
    },
  },
  shadows:{
    md: "0 0.16rem 0.36rem 0 rgba(0, 0, 0, 0.13), 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.11)"
  }
};
