import React from "react";
import { IconButton as ChakraIconButton } from "@chakra-ui/core";

const communStyles = {
  transition:
    "background 250ms ease-in-out 0s, transform 150ms ease 0s, opacity 250ms ease 0s",
  _active: { top: "0.1rem" },
};

const variantStyles = {
  solid: {
    ...communStyles,
    boxShadow:
      "md",
  },
  outline: {
    ...communStyles,
    boxShadow:
      "md",
    borderWidth: "2px",
  },

  ghost: {
    ...communStyles,
  },

  link: {
    ...communStyles,
  },
};

const variantColorStyles = {
  brand: {

  },
  neutral: {
    bg: 'neutral.10',
    color: 'neutral.500',
    _hover: {
      bg: 'neutral.100',
    }
  },
};

const IconButton = ({ children, variant, variantColor, ...rest }) => {
  return (
    <ChakraIconButton
      variant={variant}
      {...rest}
      {...variantStyles[variant]}
      {...variantColorStyles[variantColor]}
    >
      {children}
    </ChakraIconButton>
  );
};

export default IconButton;
