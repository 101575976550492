/** @jsx jsx */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { css, jsx } from "@emotion/core";
import { compose } from "recompose";
import { Flex } from "@chakra-ui/core";
import { GoogleButton, Heading } from "@volter/shared";

import { useFirebase } from "../../firebase";
import { withAlreadyAuthenticated, withAuthenticationLayout } from "../../auth";

import SignInForm from "./SignInForm";
import Separator from "../../components/Separator";

const SignInPage = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = async ({ email, password }) => {
    setIsLoading(true);
    try {
      await firebase.doSignInWithEmailAndPassword(email, password);
      history.replace(from);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Sign In | Volter</title>
      </Helmet>
      <Flex flex="1" justify="center">
        <Flex
          direction="column"
          align="center"
          justify="center"
          padding={2}
          margin="0px auto"
          width={["300px", "300px", "350px", "400px"]}
        >
          <Heading as="h1" size="lg" marginBottom={30} color="neutral.600"> 
            Welcome back, Please login to your{" "}
            <span
              css={css`
                background-image: linear-gradient(
                  180deg,
                  transparent 71%,
                  #f4511e85 0
                );
                background-size: 100% 100%;
                background-repeat: no-repeat;
                text-decoration: none;
              `}
            >
              Volter
            </span>{" "}
            account.
          </Heading>

          <GoogleButton
            width="100%"
            onClick={() => {
              firebase.doSignInWithGoogle();
            }}
          >
            Continue with google
          </GoogleButton>
          <Separator content="Or" margin="30px 0" />
          <SignInForm isLoading={isLoading} submit={onSubmitHandler} />
        </Flex>
      </Flex>
    </React.Fragment>
  );
};

export default compose(
  withAlreadyAuthenticated,
  withAuthenticationLayout
)(SignInPage);
