import React from "react";
import { Flex, Text, Box } from "@chakra-ui/core";
import { Input, IconButton } from "@volter/shared";
import { FiFilter, FiPlus, FiMenu } from "react-icons/fi";

const Header = ({ toggleMenu }) => {
  return (
    <Flex p="30px 20px" direction={["column", "column", "column"]}>
      <Flex marginBottom="30px" alignItems="center">
        <IconButton
          variant="solid"
          variantColor="neutral"
          aria-label="Settings"
          fontSize="30px"
          marginRight="30px"
          icon={FiMenu}
          d={["inline-flex", "inline-flex", "none"]}
          onClick={() => toggleMenu()}
        />
        <Text fontSize="xl" fontWeight="bold" color="neutral.600">
          All bookmarks
        </Text>
        <IconButton
          variant="solid"
          variantColor="neutral"
          aria-label="Settings"
          fontSize="30px"
          marginLeft="auto"
          icon={FiPlus}
        />
      </Flex>

      <Flex>
        <Box flex="1">
          <Input />
        </Box>
        <IconButton
          variant="solid"
          variantColor="neutral"
          aria-label="Settings"
          fontSize="30px"
          marginLeft={["15px", "30px", "30px"]}
          icon={FiFilter}
        />
      </Flex>
    </Flex>
  );
};

export default Header;
