import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { MainContainer } from "@volter/shared";

import { withAuthentication } from "./auth";
import ScrollToTop from "./components/ScrollToTop";

import * as ROUTES from "./constants/routes";

import MainLayout from "./components/MainLayout";
import SignUpPage from "./views/SignUpPage";
import SignInPage from "./views/SignInPage";
import PasswordForgetPage from "./views/PasswordForgetPage";
import HomePage from "./views/HomePage";

const App = () => {
  useEffect(()=>{
    console.log('count')
  })
  return (
    <MainContainer>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path={ROUTES.SIGN_UP} component={SignUpPage} exact />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} exact />
          <Route
            path={ROUTES.PASSWORD_FORGET}
            component={PasswordForgetPage}
            exact
          />
          <Route
            path={ROUTES.HOME}
            component={() => (
              <MainLayout>
                <HomePage />
              </MainLayout>
            )}
          />
        </Switch>
      </Router>
    </MainContainer>
  );
};

export default withAuthentication(App);
