import React, { useContext } from "react";
import { CSSReset } from "@chakra-ui/core";
import { Box } from "@chakra-ui/core";

const MainContainer = ({ children }) => {
  return (
    <React.Fragment>
      <CSSReset />
      <Box
        bg="neutral.50"
        height="100%"
        transition="background 0.4s"
        overflowY="auto"
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

export default MainContainer;
