import React from "react";
import { Box } from "@chakra-ui/core";

import { ReactComponent as LogoSvg } from "../../assets/images/volter-logo.svg";

const Logo = ({ size, ...rest }) => {
  return (
    <Box width={`${size}px`} height={`${size}px`} {...rest}>
      <LogoSvg />
    </Box>
  );
};

export default Logo;
