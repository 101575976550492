import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input, Button } from "@volter/shared";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/core";

const SignInForm = ({ submit, isLoading }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("This field should be an email")
        .required("This field is required"),
      password: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      submit(values);
    },
  });
  return (
    <Box as="form" width="100%" onSubmit={formik.handleSubmit}>
      <FormControl isInvalid={formik.errors.email && formik.touched.email}>
        <FormLabel htmlFor="email" color="neutral.600">Email</FormLabel>

        <Input
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={formik.errors.password && formik.touched.password}
        marginTop="15px"
      >
        <FormLabel htmlFor="lastName" color="neutral.600">Password</FormLabel>

        <Input
          id="password"
          name="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
      </FormControl>
      <Flex>
        <Button
          type="button"
          variant="ghost"
          variantColor="brand"
          marginTop="10px"
          marginLeft="auto"
        >
          Forgot password ?
        </Button>
      </Flex>

      <Flex width="100%" marginTop="20px" justify="space-between">
        <Button
          type="submit"
          variant="solid"
          variantColor="brand"
          isLoading={isLoading}
          flex="1"
        >
          Sign in
        </Button>
      </Flex>
    </Box>
  );
};

export default SignInForm;
