import React from "react";
import { Button as ChakraButton } from "@chakra-ui/core";

const communStyles = {
  transition:
    "background 250ms ease-in-out 0s, transform 150ms ease 0s, opacity 250ms ease 0s",
  _active: { top: "0.1rem" },
};

const styles = {
  solid: {
    ...communStyles,
    boxShadow: "md",
  },
  outline: {
    ...communStyles,
    boxShadow: "md",
    borderWidth: "2px",
  },

  ghost: {
    ...communStyles,
  },

  link: {
    ...communStyles,
  },
};

const Button = ({ children, variant, ...rest }) => {
  return (
    <ChakraButton variant={variant} {...rest} {...styles[variant]}>
      {children}
    </ChakraButton>
  );
};

export default Button;
