/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { useTheme } from "@chakra-ui/core";

const Separator = ({ margin, content }) => {
  const theme = useTheme();
  return (
    <hr
      css={css`
        overflow: visible;
        padding: 0;
        border: none;
        border-top: 1px solid ${theme.colors.neutral["400"]};
        color: ${theme.colors.neutral["400"]};
        text-align: center;
        width: 100%;
        margin: ${margin};

        &:after {
          content: "Or";
          display: inline-block;
          position: relative;
          top: -13px;
          padding: 0 16px;
          background: ${theme.colors.neutral["50"]};
        }
      `}
    />
  );
};

export default React.memo(Separator);
