import React, { useState, useEffect } from "react";
import { ThemeProvider as ChakraThemeProvider } from "@chakra-ui/core";
import useDarkMode from "use-dark-mode";

import { lightTheme, darkTheme } from "./theme";

const ThemeContext = React.createContext({
  dark: false,
  toggle: () => {},
});

export default ThemeContext;

export const ThemeProvider = ({ children }) => {
  const { value, toggle } = useDarkMode(false, {
    onChange: null,
  });
  const [theme, setTheme] = useState(value ? darkTheme : lightTheme);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setTheme(value ? darkTheme : lightTheme);
  }, [value]);

  const body = (
    <ThemeContext.Provider
      value={{
        dark: value,
        toggle
      }}
    >
      <ChakraThemeProvider theme={theme}>{children}</ChakraThemeProvider>
    </ThemeContext.Provider>
  );

  if (!mounted) {
    return <div style={{ visibility: "hidden" }}>{body}</div>;
  }

  return body;
};
