import React, { useState, useContext } from "react";

import { Button } from "@volter/shared";

import { useFirebase } from "../firebase";
import AuthUserContext from "./AuthUserContext";

const needsEmailVerification = (authUser) =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map((provider) => provider.providerId)
    .includes("password");

const withEmailVerification = (Component) => {
  const WithEmailVerification = (props) => {
    const firebase = useFirebase();
    const authUser = useContext(AuthUserContext);
    const [isSent, setIsSent] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const onSendEmailVerification = async () => {
      setIsSending(true);
      try {
        await firebase.doSendEmailVerification();
        setIsSending(false);
        setIsSent(true);
      } catch (e) {
        setIsSending(false);
        console.log(e);
      }
    };

    return needsEmailVerification(authUser) ? (
      <div>
        {isSent ? (
          <div>
            <p>
              Email de vérification envoyé, verifiez votre boite email (spams
              inclus). Veuillez rafraîchir cette page quand vous aurez vérifé
              votre email
            </p>
          </div>
        ) : (
          <div>
            <p>
              Cliquez sur le button ci dessous pour verifiez votre adresse
              email, un email de vérification vous sera envoyé.
            </p>

            <Button
              variant="solid"
              colorVariant="primary"
              isLoading={isSending}
              onClick={() => onSendEmailVerification()}
            >
              Send verification email
            </Button>
          </div>
        )}
      </div>
    ) : (
      <Component {...props} />
    );
  };

  return WithEmailVerification;
};

export default withEmailVerification;
