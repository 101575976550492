import React from "react";
import FocusLock from "react-focus-lock";
import {
  FormControl,
  FormLabel,
  Stack,
  ButtonGroup,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  useTheme,
} from "@chakra-ui/core";
import { Input, Button } from "@volter/shared";

const TextInput = React.forwardRef((props, ref) => {
  return (
    <FormControl p={"10px 15px"}>
      <FormLabel htmlFor={props.id} marginBottom="20px">{props.label}</FormLabel>
      <Input ref={ref} id={props.id} {...props} />
    </FormControl>
  );
});

const Form = ({ firstFieldRef, onCancel }) => {
  const theme = useTheme();
  return (
    <Stack spacing={4} boxShadow="md">
      <TextInput
        label="Give your collection a cute name like yours"
        id="collectionName"
        ref={firstFieldRef}
        defaultValue=""
      />
      <ButtonGroup
        d="flex"
        justifyContent="flex-end"
        p={"10px 15px"}
        bg={theme.colors.neutral["100"]}
      >
        <Button variant="ghost" variantColor="brand" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="solid" variantColor="brand">
          Save
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

const PopoverForm = ({ children }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = React.useState(false);
  const firstFieldRef = React.useRef(null);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return (
    <>
      <Popover
        isOpen={isOpen}
        initialFocusRef={firstFieldRef}
        onOpen={open}
        onClose={close}
        placement="left"
        closeOnBlur={false}
        
      >
        <PopoverTrigger>
          <Box d="flex">{children}</Box>
        </PopoverTrigger>
        <PopoverContent
          zIndex={4}
          bg={theme.colors.neutral["50"]}
          border={"none"}
          minWidth={"250px"}
        >
          <FocusLock returnFocus persistentFocus={false}>
            <PopoverArrow bg={theme.colors.neutral["50"]} />
            <Form firstFieldRef={firstFieldRef} onCancel={close} />
          </FocusLock>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default PopoverForm;
