import React, { useContext } from "react";
import { Flex, useTheme, Text, Link, IconButton } from "@chakra-ui/core";
import Avatar from "react-avatar";
import { FiSettings, FiSun, FiMoon } from "react-icons/fi";
import { ThemeContext } from "@volter/shared";

import Logo from "../Logo";
import CollectionList from "./CollectionList";
import { AuthUserContext } from "../../auth";
import { useFirebase } from "../../firebase";

const Sidebar = ({ isOpen }) => {
  const authUser = useContext(AuthUserContext);
  const firebase = useFirebase();
  const { toggle, dark } = useContext(ThemeContext);
  const theme = useTheme();
  return (
    <Flex
      width="275px"
      padding="0 15px"
      background={`${theme.colors.neutral["100"]}`}
      transform={[
        isOpen ? "none" : `translate3d(calc(275px * -1), 0, 0)`,
        isOpen ? "none" : `translate3d(calc(275px * -1), 0, 0)`,
        "none",
      ]}
      transition={`transform .3s ease-in-out, width .3s ease-in-out, background .4s`}
      position="absolute"
      top={["0", "0", "unset"]}
      minHeight={"100%"}
      boxShadow={[
        isOpen && "20px 0 50px -9px #131313db",
        isOpen && "20px 0 50px -9px #131313db",
        "unset",
      ]}
      zIndex="1"
    >
      <Flex direction="column" marginTop="10px">
        <Flex alignItems="center" marginTop="20px">
          <Logo width="36px" height="36px" />
          <Text
            fontSize="xl"
            fontWeight="bold"
            marginLeft="10px"
            isTruncated={true}
            color="neutral.700"
          >
            Volter
          </Text>
          <IconButton
            variant="link"
            variantColor="neutral"
            aria-label="Settings"
            fontSize="20px"
            marginLeft="auto"
            icon={dark ? FiSun : FiMoon}
            onClick={() => toggle()}
          />
        </Flex>
        <CollectionList />
        <Flex marginTop="auto" alignItems="center" marginBottom="20px">
          <Avatar
            name={`${authUser.firstname} ${authUser.lastname}`}
            size="48"
            color={`${theme.colors.brand["500"]}`}
            round={true}
          />
          <Flex direction="column" marginLeft="10px">
            <Text
              fontWeight="bold"
              isTruncated={true}
              textTransform="capitalize"
              color="neutral.600"
            >{`${authUser.firstname} ${authUser.lastname}`}</Text>
            <Link color="neutral.500">view profile</Link>
          </Flex>

          <IconButton
            variant="link"
            variantColor="neutral"
            aria-label="Settings"
            fontSize="30px"
            marginLeft="auto"
            icon={FiSettings}
            onClick={() => firebase.doSignOut()}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Sidebar;
