import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input, Button } from "@volter/shared";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/core";

const SignUpForm = ({ submit, isLoading }) => {
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      lastname: Yup.string().required("This field is required"),
      email: Yup.string()
        .email("This field should be an email")
        .required("This field is required"),
      password: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      submit(values);
    },
  });
  return (
    <Box as="form" width="100%" onSubmit={formik.handleSubmit}>
      <Flex justify="space-between">
        <FormControl
          isInvalid={formik.errors.firstname && formik.touched.firstname}
          marginRight="10px"
        >
          <FormLabel htmlFor="firstname" color="neutral.600">First name</FormLabel>
          <Input
            id="firstname"
            name="firstname"
            value={formik.values.firstname}
            onChange={formik.handleChange}
          />
          <FormErrorMessage>{formik.errors.firstname}</FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={formik.errors.lastname && formik.touched.lastname}
          marginLeft="10px"
        >
          <FormLabel htmlFor="lastname" color="neutral.600">Last name</FormLabel>
          <Input
            id="lastname"
            name="lastname"
            value={formik.values.lastname}
            onChange={formik.handleChange}
          />
          <FormErrorMessage>{formik.errors.lastname}</FormErrorMessage>
        </FormControl>
      </Flex>
      <FormControl
        isInvalid={formik.errors.email && formik.touched.email}
        marginTop="15px"
      >
        <FormLabel htmlFor="email" color="neutral.600">Email</FormLabel>
        <Input
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={formik.errors.password && formik.touched.password}
        marginTop="15px"
      >
        <FormLabel htmlFor="lastName" color="neutral.600">Password</FormLabel>
        <Input
          id="password"
          name="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
      </FormControl>

      <Flex width="100%" marginTop="30px" justify="space-between">
        <Button
          type="submit"
          variant="solid"
          variantColor="brand"
          isLoading={isLoading}
          flex="1"
        >
          Create an account
        </Button>
      </Flex>
    </Box>
  );
};

export default SignUpForm;
